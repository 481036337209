




































































import {Component, Vue} from 'vue-property-decorator';
import {SalesTaxBankStatement, SalesTaxWorkflow} from "@/dto/salestax/SalesTaxWorkflowDTO";
import SalesTaxService from "@/services/SalesTaxService";
import {namespace} from "vuex-class";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import {SalesTaxReportFormat, SalesTaxWorkflowStatus} from "@/constants/SalesTaxConstants";
import TextFieldModal from "@/components/common/TextFieldModal.vue";

const AppModule = namespace("App");

@Component({
  computed: {
    SalesTaxReportFormat() {
      return SalesTaxReportFormat
    }
  }
})
export default class CustomerWorkflow extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  workflow: SalesTaxWorkflow | null = null;

  uploadMode = false;

  mounted(){
    this.loadWorkflow();
  }

  loadWorkflow(){
    this.startLoading();
    SalesTaxService.getWorkflowByKey(this.getKey).then(
        response => {
          this.workflow = response.data;
          this.stopLoading();
        },
        error => {
          console.log(JSON.stringify(error));
          this.stopLoading();
        }
    )
  }

  showUploadNewDocumentModal() {
    this.$modal.show(
        FileUpload,
        {
          multiple: true,
          onUploaded: (files: FileMetaDTO[]) => this.onNewFilesUploaded(files)
        }
    )
  }

  showUploadRequestedDocumentModal(s: SalesTaxBankStatement){
    this.$modal.show(
        FileUpload,
        {
          multiple: false,
          onUploaded: (files: FileMetaDTO[]) => this.onRequestedFileUploaded(files, s)
        }
    )
  }

  onRequestedFileUploaded(files: FileMetaDTO[], s: SalesTaxBankStatement) {
    s.file = files[0];
    this.startLoading();
    SalesTaxService.saveRequestedBankStatement(this.getKey, s.id, s.file).then(
        response => {
          this.$modal.hideAll();
          this.loadWorkflow();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }

  onNewFilesUploaded(files: FileMetaDTO[]) {
    console.log("HERE")
    this.startLoading();
    SalesTaxService.saveNewBankStatements(this.getKey, files).then(
        response => {
          this.$modal.hideAll();
          this.loadWorkflow();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }

  get getKey(): string {
    return this.$route.params.key;
  }


  get isUploadStage() {
    const statuses = [SalesTaxWorkflowStatus.CREATED, SalesTaxWorkflowStatus.PENDING_FOR_DOCUMENTS];
    return !!this.workflow && statuses.includes(this.workflow.status);
  }

  get isProcessingStage(){
    const statuses = [SalesTaxWorkflowStatus.DOCUMENTS_ACCEPTED, SalesTaxWorkflowStatus.ON_REVIEW];
    return !!this.workflow && statuses.includes(this.workflow.status);
  }

  get isFinalStage() {
    return this.workflow?.status == SalesTaxWorkflowStatus.ON_CUSTOMERS_APPROVAL || this.isApprovedStage;
  }

  get isRejectedStage() {
    return this.workflow?.status == SalesTaxWorkflowStatus.REJECTED_BY_CUSTOMER;
  }


  get isApprovedStage() {
    return this.workflow?.status == SalesTaxWorkflowStatus.APPROVED_BY_CUSTOMER;
  }

  approve(){
    SalesTaxService.approveOrRejectWorkflow(this.getKey, true, "").then(
        response => {
          this.$modal.hideAll();
          this.loadWorkflow();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    )
  }

  showRejectionModal(){
    this.$modal.show(
        TextFieldModal,
        {
          onOk: this.reject
        }
    )
  }

  reject(reason: string) {
    SalesTaxService.approveOrRejectWorkflow(this.getKey, false, reason).then(
        response => {
          this.loadWorkflow();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    )
  }

}
